.section__padding {
  padding: 6rem 6rem;
}

.section__padding1 {
  padding: 6rem 6rem;
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__bg {
  background: url('./assets/bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.app__Sfondo {
  background: url('./assets/Sfondo.png');
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
}

.app__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 100vh;
}

.app__wrapper1 {
  display: flex;
  align-items: center;

  min-height: 100vh;
}

.app__wrapper_info {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.app__wrapper_info1 {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.app__wrapper_img {
  flex: 1;
  width: 50%;
  height: 40vh;
  display: flex;
  justify-content: flex-start;
      margin: 2rem 0 0 0;
}

.app__wrapper_img-reverse {
  justify-content: flex-start;
  margin-right: 2rem;
}

.app__wrapper_img img {
  width: 80%;
}

.custom__button {
  display: inline-block;
  margin: 10px;
  padding: 10px 20px;
  background-color: var(--color-golden);
  color: var(--color-white);
  text-decoration: none;
  text-align: center;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-size: 1.2rem;
  font-family: var(--font-base);
  border: 1px solid var(--color-golden);
}

.custom__button:hover {
  background-color: #b05649;
}

.custom__button1 {
  display: inline-block;
  margin: 10px;
  padding: 10px 20px;
  background-color: var(--color-golden);
  color: var(--color-white);
  text-decoration: none;
  text-align: center;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-size: 1.2rem;
  font-family: var(--font-base);
  border: 1px solid var(--color-golden);
}

.custom__button1:hover {
  background-color: #b05649;
}

.p__cormorant {
  font-family: var(--font-base);
  color: var(--color-white);
  font-weight: 500;
  letter-spacing: 0.06em;
  text-transform: none;
  font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 29.9px;
  font-size: 23px;
}

.p__cormorant_1 {
  font-family: var(--font-base);
  color: var(--color-white);
  font-weight: 500;
  letter-spacing: 0.06em;
  text-transform: none;
  font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 29.9px;
  font-size: 30px;
}

.p__cormorant_2 {
  font-family: var(--font-base);
  color: var(--color-white);
  font-weight: 400;
  letter-spacing: 0.06em;
  text-transform: none;
  font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 29.9px;
  font-size: 20px;
}

.p__opensans {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-weight: 300;
  text-transform: none;
  line-height: 1.5;
  font-size: 16px;
}

.app__aboutus-content_about_text {
    font-family: var(--font-alt);
    font-weight: 300;
    font-size: 18px;
    color: var(--color-white);
    margin-bottom: 2rem;
    text-align: justify;
    line-height: 1.5;
    /* Increased line height for more space between lines */
}

.p__opensans1 {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-weight: 300;
  text-transform: none;
  line-height: 2;
  font-size: 18px;
  text-align: justify;
}

.p__opensans2 {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-weight: 300;
  text-transform: none;
  line-height: 2;
  font-size: 18px;
  text-align: center;
}

.headtext__cormorant {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-size: 70px;
  font-weight: 400;
  line-height: 83.2px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}

.headtext__cormorant_1 {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-size: 50px;
  font-weight: 400;
  line-height: 83.2px;
  letter-spacing: 0.04em;
  text-transform: none;
}

.headtext__cormorant_2 {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-size: 50px;
  font-weight: 400;
  line-height: 83.2px;
  letter-spacing: 0.04em;
  text-transform: none;
}

@media screen and (min-width: 2000px) {

  .custom__button,
  .custom__button1,
  .p__cormorant {
    font-size: 37px;
    line-height: 67px;
  }

  .p__cormorant_1 {
    font-size: 37px;
    line-height: 67px;
  }

  .p__cormorant_2 {
    font-size: 37px;
    line-height: 67px;
  }

  .p__opensans {
    font-size: 30px;
    line-height: 50px;
  }

  .p__opensans1 {
    font-size: 30px;
    line-height: 50px;
  }

        .p__opensans2 {
          font-size: 30px;
          line-height: 50px;
        }

  .headtext__cormorant {
    font-size: 150px;
    line-height: 210px;
  }

  .headtext__cormorant_1 {
    font-size: 150px;
    line-height: 210px;
  }

  .headtext__cormorant_2 {
    font-size: 150px;
    line-height: 210px;
  }
}

@media screen and (max-width: 1150px) {
  .app__wrapper {
    flex-direction: column;
  }

  .app__wrapper_img {
    margin: 2rem 0 0 0;
  }

  .app__wrapper_img-reverse {
    margin: 0 0 2rem 0;
  }

  .app__wrapper_img img {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .section__padding {
    padding: 4rem;
  }

  .section__padding1 {
    padding: 8rem;
  }
}

@media screen and (max-width: 650px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__padding1 {
    padding: 8rem 2rem;
  }

  .headtext__cormorant {
    font-family: var(--font-base);
    color: var(--color-golden);
    font-size: 40px;
    font-weight: 400;
    line-height: 65px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
  }

  .headtext__cormorant_2 {
    font-family: var(--font-base);
    color: var(--color-white);
    font-size: 20px;
    font-weight: 400;
    line-height: 55px;
    letter-spacing: 0.04em;
    text-transform: none;
  }

  .p__cormorant {
    font-size: 23px;
  }

  .p__cormorant_1 {
    font-size: 23px;
  }

  .p__cormorant_2 {
    font-size: 23px;
  }

  .p__opensans {
    font-size: 14px;
  }

  .app__bg {
    background: url('./assets/sfondocell.png');
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    background-attachment: fixed;
  }

  .app__wrapper1 {
    display: block;
    align-items: center;

    min-height: 100vh;
  }
}

@media screen and (max-width: 450px) {
  .p__opensans {
    font-size: 13px;
  }

  .p__opensans1 {
    font-family: var(--font-alt);
    color: var(--color-white);
    font-weight: 300;
    letter-spacing: 0.06em;
    text-transform: none;
    line-height: 28px;
    font-size: 18px;
  }

        .p__opensans2 {
          font-family: var(--font-alt);
          color: var(--color-white);
          font-weight: 300;
          letter-spacing: 0.06em;
          text-transform: none;
          line-height: 28px;
          font-size: 18px;
        }

  .p__cormorant {
    font-size: 19px;
  }

  .p__cormorant_1 {
    font-size: 22px;
    line-height: 28px;
  }

  .p__cormorant_2 {
    font-size: 19px;
  }

  .headtext__cormorant {
    font-size: 35px;
    line-height: 55px;
  }

  .headtext__cormorant_1 {
    font-size: 35px;
    line-height: 55px;
  }

  .headtext__cormorant_2 {
    font-size: 35px;
    line-height: 55px;
  }
}