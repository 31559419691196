.app__video {
    height: 100vh;
    position: relative;
}

.app__video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.app__video-overlay {
    position: absolute;
    inset: 0;
}

.app__video-overlay_circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid var(--color-golden);
    cursor: pointer;
}

@media screen and (max-width: 650px) {
    .app__video {
        height: 50vh;
        position: relative;
    }
    
    .app__video video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .app__video-overlay {
        position: relative;
        inset: 0;
    }
    
    .app__video-overlay_circle {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid var(--color-golden);
        cursor: pointer;
    }
}

@media screen and (max-width: 350px) {
    .app__video {
        height: 50vh;
        position: relative;
    }
    
    .app__video video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .app__video-overlay {
        position: relative;
        inset: 0;
    }
    
    .app__video-overlay_circle {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid var(--color-golden);
        cursor: pointer;
    }
}