.app__aboutus {
    position: relative;
}

.app__aboutus-overlay {
    position: absolute;
    inset: 0;
}

.app__aboutus-overlay img {
    width: 391px;
    height: 415px;
    z-index: 0;
}

.app__aboutus-content {
    width: 100%;
    z-index: 2;
}

.app__aboutus-content_about {
    display: flex;
    flex-direction: row;
    /* This ensures your items are in a row */
    align-items: center;
        flex-wrap: nowrap;
            /* Add this line */
    /* This will vertically align the image and the text in the middle */
}

.app__aboutus-content_about_text {
    /* adjust as needed */
    font-family: var(--font-alt);
    font-weight: 300;
    font-size: 18px;
    color: var(--color-white);
    margin-bottom: 2rem;
    text-align: justify;
    line-height: 1.5;
    /* allow text to take up remaining space */
    padding-right: 1rem;
    /* create a little space between the text and the image */
}

.app__aboutus-content_knife {
    margin: 2rem 4rem;
}

.app__aboutus-content_knife img {
    height: 910px;
}

.app__aboutus-content_history {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
}

.app__aboutus-content_about p,
.app__aboutus-content_history p {
    margin: 0.5rem 0;
    color: var(--color-white);
}

.image {
    /* adjust this value to resize your image */
    max-width: 300px;
    /* prevent the image from being too large */
    height: auto;
    margin: 2rem;
    /* keep the aspect ratio of the image */
}

@media screen and (min-width: 2000px) {
    .app__aboutus-content_knife img {
        height: 1110px;
    }

    .app__aboutus-content_about p,
    .app__aboutus-content_history p {
        margin: 4rem 0;
    }
}

@media screen and (max-width: 900px) {
    .app__aboutus-content {
        flex-direction: column;
    }

    .app__aboutus-content_knife {
        margin: 4rem 0;
    }
}

@media screen and (max-width: 650px) {
    .app__aboutus-overlay img {
        width: 80%;
        height: 320px;
    }
}