@import url(https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Charm&display=swap);
:root {
  --font-base: 'Charm', serif;
  --font-alt: 'Open Sans', sans-serif;
  
  --color-golden: #DCCA87;
  --color-black: #0C0C0C;
  --color-gray: #545454;
  --color-crimson: #F5EFDB;
  --color-grey: #AAAAAA;
  --color-white: #FFFFFF;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  color: unset;
  text-decoration: none;
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.app__aboutus {
    position: relative;
}

.app__aboutus-overlay {
    position: absolute;
    inset: 0;
}

.app__aboutus-overlay img {
    width: 391px;
    height: 415px;
    z-index: 0;
}

.app__aboutus-content {
    width: 100%;
    z-index: 2;
}

.app__aboutus-content_about {
    display: flex;
    flex-direction: row;
    /* This ensures your items are in a row */
    align-items: center;
        flex-wrap: nowrap;
            /* Add this line */
    /* This will vertically align the image and the text in the middle */
}

.app__aboutus-content_about_text {
    /* adjust as needed */
    font-family: var(--font-alt);
    font-weight: 300;
    font-size: 18px;
    color: var(--color-white);
    margin-bottom: 2rem;
    text-align: justify;
    line-height: 1.5;
    /* allow text to take up remaining space */
    padding-right: 1rem;
    /* create a little space between the text and the image */
}

.app__aboutus-content_knife {
    margin: 2rem 4rem;
}

.app__aboutus-content_knife img {
    height: 910px;
}

.app__aboutus-content_history {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
}

.app__aboutus-content_about p,
.app__aboutus-content_history p {
    margin: 0.5rem 0;
    color: var(--color-white);
}

.image {
    /* adjust this value to resize your image */
    max-width: 300px;
    /* prevent the image from being too large */
    height: auto;
    margin: 2rem;
    /* keep the aspect ratio of the image */
}

@media screen and (min-width: 2000px) {
    .app__aboutus-content_knife img {
        height: 1110px;
    }

    .app__aboutus-content_about p,
    .app__aboutus-content_history p {
        margin: 4rem 0;
    }
}

@media screen and (max-width: 900px) {
    .app__aboutus-content {
        flex-direction: column;
    }

    .app__aboutus-content_knife {
        margin: 4rem 0;
    }
}

@media screen and (max-width: 650px) {
    .app__aboutus-overlay img {
        width: 80%;
        height: 320px;
    }
}
.app__chef-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-top: 1rem;
}

.app__chef-content_quote {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.app__chef-content_quote img {
    width: 47px;
    height: 40px;
    margin: 0 1rem 1rem 0;
}

.app__chef-sign {
    width: 100%;
    margin-top: 3rem;
}

.app__chef-sign p:first-child {
    font-family: var(--font-base);
    font-weight: 400;
    font-size: 32px;
    line-height: 41.6px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: var(--color-golden);
}

.app__chef-sign img {
    width: 250px;
    margin-top: 3rem;
}

@media screen and (min-width: 2000px) {
    .app__chef-sign img {
        width: 370px;
    }
}

@media screen and (max-width: 450px) {
    .app__chef-sign img {
        width: 80%;
    }
}

@media screen and (max-width: 650px) {
    .app__chef-sign img {
        width: 80%;
    }
}
.app__newsletter {
    padding: 2rem 4rem;
    border: 1px solid var(--color-golden);
    background: rgb(255, 255, 255, 0.2);
}

.app__newsletter-heading {
    text-align: center;
}

.app__newsletter-input {
    flex-direction: row;
}

.app__newsletter-input input {
    width: 620px;
    border: 1px solid var(--color-golden);
    border-radius: 5px;
    font-size: 1rem;
    font-family: var(--font-base);
    color: var(--color-white);

    margin-right: 2rem;
    padding: 0.75rem 1rem;
}

.app__newsletter-input button {
    width: -webkit-max-content;
    width: max-content;
}

.button-link {
    display: inline-block;
    margin: 10px;
    padding: 10px 20px;
    background-color: var(--color-golden);
    color: var(--color-white);
    text-decoration: none;
    text-align: center;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    font-size: 1.5rem;
    font-family: var(--font-base);
    border: 1px solid var(--color-golden);
}

.button-link:hover {
    background-color: #b05649;
}

@media screen and (min-width: 2000px) {
    .app__newsletter-input input {
        font-size: 2rem;
    }

    .button-link {
        font-size: 2rem;
    }
}

@media screen and (max-width: 990px) {
    .app__newsletter-input {
        flex-direction: column;
    }

    .app__newsletter-input input {
        margin: 0 0 2rem 0;
        width: 100%;
    }

    .button-link {
        margin: 0 0 2rem 0;
        width: 100%;
    }
}

@media screen and (max-width: 650px) {
    .app__newsletter {
        padding: 2rem 4rem;
        border: 1px solid var(--color-white);
        background: rgba(255, 255, 255, 0.2);
    }
}

@media screen and (max-width: 300px) {
    .app__newsletter-heading h1 {
        font-size: 32px;
        line-height: 50px;
    }
}
.app__footerOverlay {
    width: 100%;
    height: 100%;
    z-index: -1;

    display: flex;
    flex-direction: column;
    position: absolute;
}

.app__footerOverlay-black {
    height: 25%;
}

.app__footerOverlay-img {
    height: 75%;
}

.app__menuitem {
    width: 100%;
    margin: 1rem 0;

    display: flex;
    flex-direction: column;
}

.app__menuitem-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.app__menuitem-sub {
    width: 100%;
    margin-top: 0.2rem;
}

.app__menuitem-name {
    flex: 1 1;
}

.app__menuitem-dash {
    width: 90px;
    height: 1px;
    background: var(--color-golden);
    margin: 0 1rem;
}

.app__menuitem-price {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.app__navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-black);

    padding: 1rem 2rem;
}

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.app__navbar-logo img {
    width: 300px;
}

.app__navbar-login {
    display: block;
    align-items: flex-end;
}

.app__navbar-links {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;

    list-style: none;
}

.app__navbar-links li {
    margin: 0 1rem;
    cursor: pointer;
}

.app__navbar-links li:hover {
    color: var(--color-white);
}

.app__navbar-smallscreen {
    display: none;
}

.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transition: .5s ease;

    flex-direction: column;
    z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color: var(--color-golden);
    cursor: pointer;

    position: absolute;
    top: 20px;
    right: 20px;
}

.app__navbar-smallscreen_links {
    list-style: none;
}

.app__navbar-smallscreen_links li {
    margin: 2rem;
    cursor: pointer;
    color: var(--color-golden);
    font-size: 2rem;
    text-align: center;
    font-family: var(--font-base);
}

.app__navbar-smallscreen_links li:hover {
    color: var(--color-white);
}

@media screen and (min-width: 2000px) {
    .app__navbar-logo img {
        width: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .app__navbar-links {
        display: none;
    }

    .app__navbar-smallscreen {
        display: flex;
    }
}

@media screen and (max-width: 650px) {
    .app__navbar {
        padding: 1rem;
    }

    .app__navbar-logo img {
        width: 160px;
    }
}
.app__footer {
    width: 100%;
    position: relative;
    z-index: 1;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    background: var(--color-black);
    padding-top: 0;
}

.app__footer-links {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    margin-top: 5rem;
    padding: 0 2rem;
}

.app__footer-links_contact,
.app__footer-links_logo,
.app__footer-links_work {
    flex: 1 1;
    margin: 1rem;
    text-align: center;
}

.app__footer-links_logo img:first-child {
    width: 210px;
    margin-bottom: 0.75rem;
}


.app__footer-links_work p:nth-child(2n + 1) {
    margin-bottom: 1rem;
}

.app__footer-links_icons {
    margin-top: 2rem;
}

.app__footer-links_icons svg {
    color: var(--color-white);
    margin: 0.8rem;
    font-size: 30px;
    cursor: pointer;
}

.app__footer-links_icons svg:hover {
    color: var(--color-golden);
}

.app__footer-headtext {
    font-family: var(--font-base);
    color: var(--color-white);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    font-size: 32px;
    line-height: 41.6px;
    margin-bottom: 1rem;
}

.footer__copyright {
    margin-top: 3rem;
}

@media screen and (min-width: 2000px) {
    .app__footer-headtext {
        font-size: 51px;
        line-height: 61.6px;
    }
}

@media screen and (max-width: 1150px) {
    .app__footer-links {
        align-items: center;
        flex-direction: column;
        padding: 0;
    }

    .app__footer-links_contact,
    .app__footer-links_logo,
    .app__footer-links_work {
        margin: 2rem 0;
        width: 100%;
    }
}

@media screen and (max-width: 650px) {
    .app__footer {
        padding: 0 0 2rem 0;
    }
}

@media screen and (max-width: 350px) {
    .app__footer-links_logo img:first-child {
        width: 80%;
    }
}

.header__unique {
    display: block !important;
    justify-content: initial !important;
    align-items: initial !important;
    height: auto !important;
    min-height: 0 !important;
}

.header__unique .app__header-img {
    display: block;
    width: 100%;
    height: 85vh;
    object-fit: cover;
    margin: 0 !important;
    padding: 0 !important;
}

.header__unique * {
    box-sizing: inherit;
}

.header__unique,
.header__unique * {
    margin: 0 !important;
    margin-bottom: 0 !important;
    padding: 0 !important;
    box-sizing: border-box;
}
.app__video {
    height: 100vh;
    position: relative;
}

.app__video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.app__video-overlay {
    position: absolute;
    inset: 0;
}

.app__video-overlay_circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid var(--color-golden);
    cursor: pointer;
}

@media screen and (max-width: 650px) {
    .app__video {
        height: 50vh;
        position: relative;
    }
    
    .app__video video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .app__video-overlay {
        position: relative;
        inset: 0;
    }
    
    .app__video-overlay_circle {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid var(--color-golden);
        cursor: pointer;
    }
}

@media screen and (max-width: 350px) {
    .app__video {
        height: 50vh;
        position: relative;
    }
    
    .app__video video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .app__video-overlay {
        position: relative;
        inset: 0;
    }
    
    .app__video-overlay_circle {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid var(--color-golden);
        cursor: pointer;
    }
}
.app__laurels_awards {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.app__laurels_awards-card {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 230px;

    margin: 1rem;
}

@media screen and (min-width: 1900px) {
    .app__laurels_awards-card {
        min-width: 390px;
    }
}

.app__laurels_awards-card img {
    width: 50px;
    height: 50px;
}

.app__laurels_awards-card_content {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}

@media screen and (max-width: 450px) {
    .app__laurels_awards-card {
        min-width: 100%;
        margin: 1rem 0;
    }
}

.app__specialMenu {
    flex-direction: column;
}

.app__specialMenu-title {
    margin-bottom: 2rem;
    text-align: center;
}

.app__specialMenu-menu {
    width: 100%;
    margin: 1rem 0;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
}

.app__specialMenu-menu1 {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
}

.app__specialMenu-menu_heading {
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 45px;
    line-height: 58.5px;
    letter-spacing: 0.04em;
    color: var(--color-white);
        text-align: right !important;
            /* Add this line */
}

.app__specialMenu-item_heading {
    font-family: var(--font-base);
    font-weight: bold;
    font-size: 24px;
    color: var(--color-golden);
    margin-bottom: 1rem;
    text-align: left !important;
}

.app__specialMenu-item_text {
    font-family: var(--font-alt);
    font-weight: 300;
    font-size: 18px;
    color: var(--color-white);
    margin-bottom: 2rem;
    text-align: justify;
        line-height: 1.5;
            /* Increased line height for more space between lines */
}

.app__specialMenu-item_text1 {
    font-family: var(--font-alt);
    font-weight: 300;
    font-size: 18px;
    color: var(--color-white);
    margin-bottom: 2rem;
    text-align: justify;
    line-height: 1.5;
        font-style: italic;
            /* This will make your text italic */
    /* Increased line height for more space between lines */
}

.app__specialMenu-item_description {
    max-width: 80%;
}

.app__specialMenu-item_description1 {
    max-width: 100%;
}

.app__specialMenu-item_price {
    text-align: right;
    font-family: var(--font-alt);
    font-size: 18px;
    color: var(--color-white);
}

.app__specialMenu-item_price p {
    margin-bottom: 0.5rem;
}

.app__specialMenu-menu_wine,
.app__specialMenu-menu_cocktails {
    flex: 1 1;
    width: 100%;
    flex-direction: column;
}

.app__specialMenu-menu_img {
    width: 500px;
    border: 2px solid #DCCA87;
    margin: 2rem;
}

.app__specialMenu-menu_img img {
    width: 100%;
    height: auto;
}

.app__specialMenu_menu_items {
    display: flex;
    flex-direction: column;

    margin: 2rem 0;
    width: 100%;
}

@media screen and (min-width: 2000px) {
    .app__specialMenu-menu_img {
        width: 650px;
    }

    .app__specialMenu-menu_img img {
        height: 920px;
    }
}

@media screen and (max-width: 1150px) {
    .app__specialMenu-menu {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .app__specialMenu-menu_img {
        margin: 3rem 0;
    }
}

@media screen and (max-width: 650px) {
    .app__specialMenu-menu_img {
        width: 100%;
    }

    .app__specialMenu-menu_heading {
        font-size: 35px;
        line-height: 48.5px;
    }

    .app__specialMenu-item_description,
    .app__specialMenu-item_price {
        max-width: 100%;
    }
}
.app__aboutus {
    position: relative;
}

.app__aboutus-overlay {
    position: absolute;
    inset: 0;
}

.app__aboutus-overlay img {
    width: 391px;
    height: 415px;
    z-index: 0;
}

.app__aboutus-content {
    width: 100%;
    z-index: 2;
}

.app__aboutus-content_about {
    flex: 1 1;
    display: flex;
    justify-content: left;
    align-items: left;
    flex-direction: column;
}

.app__aboutus-content_knife {
    margin: 2rem 4rem;
}

.app__aboutus-content_knife img {
    height: 910px;
}

.app__aboutus-content_history {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
}

.app__aboutus-content_about p,
.app__aboutus-content_history p {
    margin: 0.5rem 0;
    color: var(--color-white);
}

@media screen and (min-width: 2000px) {
    .app__aboutus-content_knife img {
        height: 1110px;
    }

    .app__aboutus-content_about p,
    .app__aboutus-content_history p {
        margin: 4rem 0;
    }
}

@media screen and (max-width: 900px) {
    .app__aboutus-content {
        flex-direction: column;
    }

    .app__aboutus-content_knife {
        margin: 4rem 0;
    }
}

@media screen and (max-width: 650px) {
    .app__aboutus-overlay img {
        width: 80%;
        height: 320px;
    }
}
.section__padding {
  padding: 6rem 6rem;
}

.section__padding1 {
  padding: 6rem 6rem;
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__bg {
  background: url(/static/media/bg.a3ba148e.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.app__Sfondo {
  background: url(/static/media/Sfondo.380f3636.png);
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
}

.app__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 100vh;
}

.app__wrapper1 {
  display: flex;
  align-items: center;

  min-height: 100vh;
}

.app__wrapper_info {
  flex: 1 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.app__wrapper_info1 {
  flex: 1 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.app__wrapper_img {
  flex: 1 1;
  width: 50%;
  height: 40vh;
  display: flex;
  justify-content: flex-start;
      margin: 2rem 0 0 0;
}

.app__wrapper_img-reverse {
  justify-content: flex-start;
  margin-right: 2rem;
}

.app__wrapper_img img {
  width: 80%;
}

.custom__button {
  display: inline-block;
  margin: 10px;
  padding: 10px 20px;
  background-color: var(--color-golden);
  color: var(--color-white);
  text-decoration: none;
  text-align: center;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-size: 1.2rem;
  font-family: var(--font-base);
  border: 1px solid var(--color-golden);
}

.custom__button:hover {
  background-color: #b05649;
}

.custom__button1 {
  display: inline-block;
  margin: 10px;
  padding: 10px 20px;
  background-color: var(--color-golden);
  color: var(--color-white);
  text-decoration: none;
  text-align: center;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-size: 1.2rem;
  font-family: var(--font-base);
  border: 1px solid var(--color-golden);
}

.custom__button1:hover {
  background-color: #b05649;
}

.p__cormorant {
  font-family: var(--font-base);
  color: var(--color-white);
  font-weight: 500;
  letter-spacing: 0.06em;
  text-transform: none;
  -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
          font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 29.9px;
  font-size: 23px;
}

.p__cormorant_1 {
  font-family: var(--font-base);
  color: var(--color-white);
  font-weight: 500;
  letter-spacing: 0.06em;
  text-transform: none;
  -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
          font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 29.9px;
  font-size: 30px;
}

.p__cormorant_2 {
  font-family: var(--font-base);
  color: var(--color-white);
  font-weight: 400;
  letter-spacing: 0.06em;
  text-transform: none;
  -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
          font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 29.9px;
  font-size: 20px;
}

.p__opensans {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-weight: 300;
  text-transform: none;
  line-height: 1.5;
  font-size: 16px;
}

.app__aboutus-content_about_text {
    font-family: var(--font-alt);
    font-weight: 300;
    font-size: 18px;
    color: var(--color-white);
    margin-bottom: 2rem;
    text-align: justify;
    line-height: 1.5;
    /* Increased line height for more space between lines */
}

.p__opensans1 {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-weight: 300;
  text-transform: none;
  line-height: 2;
  font-size: 18px;
  text-align: justify;
}

.p__opensans2 {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-weight: 300;
  text-transform: none;
  line-height: 2;
  font-size: 18px;
  text-align: center;
}

.headtext__cormorant {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-size: 70px;
  font-weight: 400;
  line-height: 83.2px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}

.headtext__cormorant_1 {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-size: 50px;
  font-weight: 400;
  line-height: 83.2px;
  letter-spacing: 0.04em;
  text-transform: none;
}

.headtext__cormorant_2 {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-size: 50px;
  font-weight: 400;
  line-height: 83.2px;
  letter-spacing: 0.04em;
  text-transform: none;
}

@media screen and (min-width: 2000px) {

  .custom__button,
  .custom__button1,
  .p__cormorant {
    font-size: 37px;
    line-height: 67px;
  }

  .p__cormorant_1 {
    font-size: 37px;
    line-height: 67px;
  }

  .p__cormorant_2 {
    font-size: 37px;
    line-height: 67px;
  }

  .p__opensans {
    font-size: 30px;
    line-height: 50px;
  }

  .p__opensans1 {
    font-size: 30px;
    line-height: 50px;
  }

        .p__opensans2 {
          font-size: 30px;
          line-height: 50px;
        }

  .headtext__cormorant {
    font-size: 150px;
    line-height: 210px;
  }

  .headtext__cormorant_1 {
    font-size: 150px;
    line-height: 210px;
  }

  .headtext__cormorant_2 {
    font-size: 150px;
    line-height: 210px;
  }
}

@media screen and (max-width: 1150px) {
  .app__wrapper {
    flex-direction: column;
  }

  .app__wrapper_img {
    margin: 2rem 0 0 0;
  }

  .app__wrapper_img-reverse {
    margin: 0 0 2rem 0;
  }

  .app__wrapper_img img {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .section__padding {
    padding: 4rem;
  }

  .section__padding1 {
    padding: 8rem;
  }
}

@media screen and (max-width: 650px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__padding1 {
    padding: 8rem 2rem;
  }

  .headtext__cormorant {
    font-family: var(--font-base);
    color: var(--color-golden);
    font-size: 40px;
    font-weight: 400;
    line-height: 65px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
  }

  .headtext__cormorant_2 {
    font-family: var(--font-base);
    color: var(--color-white);
    font-size: 20px;
    font-weight: 400;
    line-height: 55px;
    letter-spacing: 0.04em;
    text-transform: none;
  }

  .p__cormorant {
    font-size: 23px;
  }

  .p__cormorant_1 {
    font-size: 23px;
  }

  .p__cormorant_2 {
    font-size: 23px;
  }

  .p__opensans {
    font-size: 14px;
  }

  .app__bg {
    background: url(/static/media/sfondocell.82f17263.png);
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    background-attachment: fixed;
  }

  .app__wrapper1 {
    display: block;
    align-items: center;

    min-height: 100vh;
  }
}

@media screen and (max-width: 450px) {
  .p__opensans {
    font-size: 13px;
  }

  .p__opensans1 {
    font-family: var(--font-alt);
    color: var(--color-white);
    font-weight: 300;
    letter-spacing: 0.06em;
    text-transform: none;
    line-height: 28px;
    font-size: 18px;
  }

        .p__opensans2 {
          font-family: var(--font-alt);
          color: var(--color-white);
          font-weight: 300;
          letter-spacing: 0.06em;
          text-transform: none;
          line-height: 28px;
          font-size: 18px;
        }

  .p__cormorant {
    font-size: 19px;
  }

  .p__cormorant_1 {
    font-size: 22px;
    line-height: 28px;
  }

  .p__cormorant_2 {
    font-size: 19px;
  }

  .headtext__cormorant {
    font-size: 35px;
    line-height: 55px;
  }

  .headtext__cormorant_1 {
    font-size: 35px;
    line-height: 55px;
  }

  .headtext__cormorant_2 {
    font-size: 35px;
    line-height: 55px;
  }
}
@-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Blue */
    border-radius: 50%;
    -webkit-animation: spinner 1.5s linear infinite;
            animation: spinner 1.5s linear infinite;
  }
  
  .spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 350px;
  }
