.header__unique {
    display: block !important;
    justify-content: initial !important;
    align-items: initial !important;
    height: auto !important;
    min-height: 0 !important;
}

.header__unique .app__header-img {
    display: block;
    width: 100%;
    height: 85vh;
    object-fit: cover;
    margin: 0 !important;
    padding: 0 !important;
}

.header__unique * {
    box-sizing: inherit;
}

.header__unique,
.header__unique * {
    margin: 0 !important;
    margin-bottom: 0 !important;
    padding: 0 !important;
    box-sizing: border-box;
}