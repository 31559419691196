.app__newsletter {
    padding: 2rem 4rem;
    border: 1px solid var(--color-golden);
    background: rgb(255, 255, 255, 0.2);
}

.app__newsletter-heading {
    text-align: center;
}

.app__newsletter-input {
    flex-direction: row;
}

.app__newsletter-input input {
    width: 620px;
    border: 1px solid var(--color-golden);
    border-radius: 5px;
    font-size: 1rem;
    font-family: var(--font-base);
    color: var(--color-white);

    margin-right: 2rem;
    padding: 0.75rem 1rem;
}

.app__newsletter-input button {
    width: max-content;
}

.button-link {
    display: inline-block;
    margin: 10px;
    padding: 10px 20px;
    background-color: var(--color-golden);
    color: var(--color-white);
    text-decoration: none;
    text-align: center;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    font-size: 1.5rem;
    font-family: var(--font-base);
    border: 1px solid var(--color-golden);
}

.button-link:hover {
    background-color: #b05649;
}

@media screen and (min-width: 2000px) {
    .app__newsletter-input input {
        font-size: 2rem;
    }

    .button-link {
        font-size: 2rem;
    }
}

@media screen and (max-width: 990px) {
    .app__newsletter-input {
        flex-direction: column;
    }

    .app__newsletter-input input {
        margin: 0 0 2rem 0;
        width: 100%;
    }

    .button-link {
        margin: 0 0 2rem 0;
        width: 100%;
    }
}

@media screen and (max-width: 650px) {
    .app__newsletter {
        padding: 2rem 4rem;
        border: 1px solid var(--color-white);
        background: rgba(255, 255, 255, 0.2);
    }
}

@media screen and (max-width: 300px) {
    .app__newsletter-heading h1 {
        font-size: 32px;
        line-height: 50px;
    }
}