.app__specialMenu {
    flex-direction: column;
}

.app__specialMenu-title {
    margin-bottom: 2rem;
    text-align: center;
}

.app__specialMenu-menu {
    width: 100%;
    margin: 1rem 0;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
}

.app__specialMenu-menu1 {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
}

.app__specialMenu-menu_heading {
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 45px;
    line-height: 58.5px;
    letter-spacing: 0.04em;
    color: var(--color-white);
        text-align: right !important;
            /* Add this line */
}

.app__specialMenu-item_heading {
    font-family: var(--font-base);
    font-weight: bold;
    font-size: 24px;
    color: var(--color-golden);
    margin-bottom: 1rem;
    text-align: left !important;
}

.app__specialMenu-item_text {
    font-family: var(--font-alt);
    font-weight: 300;
    font-size: 18px;
    color: var(--color-white);
    margin-bottom: 2rem;
    text-align: justify;
        line-height: 1.5;
            /* Increased line height for more space between lines */
}

.app__specialMenu-item_text1 {
    font-family: var(--font-alt);
    font-weight: 300;
    font-size: 18px;
    color: var(--color-white);
    margin-bottom: 2rem;
    text-align: justify;
    line-height: 1.5;
        font-style: italic;
            /* This will make your text italic */
    /* Increased line height for more space between lines */
}

.app__specialMenu-item_description {
    max-width: 80%;
}

.app__specialMenu-item_description1 {
    max-width: 100%;
}

.app__specialMenu-item_price {
    text-align: right;
    font-family: var(--font-alt);
    font-size: 18px;
    color: var(--color-white);
}

.app__specialMenu-item_price p {
    margin-bottom: 0.5rem;
}

.app__specialMenu-menu_wine,
.app__specialMenu-menu_cocktails {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__specialMenu-menu_img {
    width: 500px;
    border: 2px solid #DCCA87;
    margin: 2rem;
}

.app__specialMenu-menu_img img {
    width: 100%;
    height: auto;
}

.app__specialMenu_menu_items {
    display: flex;
    flex-direction: column;

    margin: 2rem 0;
    width: 100%;
}

@media screen and (min-width: 2000px) {
    .app__specialMenu-menu_img {
        width: 650px;
    }

    .app__specialMenu-menu_img img {
        height: 920px;
    }
}

@media screen and (max-width: 1150px) {
    .app__specialMenu-menu {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .app__specialMenu-menu_img {
        margin: 3rem 0;
    }
}

@media screen and (max-width: 650px) {
    .app__specialMenu-menu_img {
        width: 100%;
    }

    .app__specialMenu-menu_heading {
        font-size: 35px;
        line-height: 48.5px;
    }

    .app__specialMenu-item_description,
    .app__specialMenu-item_price {
        max-width: 100%;
    }
}